import { Dispatch } from 'redux';
import { RealTimeActionTypes, REALTIME_USERMESSAGE_RECEIVED, REALTIME_PLAYERMESSAGE_RECEIVED, REALTIME_CONNECTION_STATUS_CHANGED, Action } from './RealTimeActionTypes';
import { UserStatusMessage, PlayerMessage } from '../../models';
import { Guid } from 'guid-typescript';
//import AzureFunctions from './AzureFunctions';
import { createAction } from 'typesafe-actions';
import { HubConnectionState } from '@microsoft/signalr';
import { getUserId } from '../../utils/localStorage';
import constants from '../../utils/constants';

export const SendPlayerMessage = (playerMessage: PlayerMessage) => async (dispatch: Dispatch<RealTimeActionTypes>) => {
  try {

    let message = playerMessage;
    message.messageId = Guid.create().toString();
    message.senderUserId = getUserId();

    //await AzureFunctions.post('/playerMessages', JSON.stringify(message), {headers:{"x-ms-client-principal-name":message.senderUserId}});
  } catch (e) {
    console.log('SendUserMessage Exception: ' + e);
  }
}

export const OnUserMessageReceived = createAction(REALTIME_USERMESSAGE_RECEIVED)<UserStatusMessage>();

export const OnPlayerMessageReceived = createAction(REALTIME_PLAYERMESSAGE_RECEIVED)<PlayerMessage>();

export const OnRealTimeConnectionStatusChanged = createAction(REALTIME_CONNECTION_STATUS_CHANGED)<HubConnectionState>();

// export const InvokeGroupMessage = (groupUser: GroupUser): Action => async (dispatch, getState, invoke) => {
//   let message = groupUser;
//   message.userId = getUserId();
//   await invoke('GroupAction', groupUser);
// }

export const InvokeUserMessage = (userMessage: UserStatusMessage): Action => async (dispatch, getState, invoke) => {
  let message = userMessage;
  message.messageId = Guid.create().toString();
  message.sender = getUserId();
  message.sessionId = constants.sessionId;
  //console.log('send user status message', message);
  await invoke('SendUserStatusMessage', userMessage);
}

export const InvokePlayerMessage = (playerMessage: PlayerMessage): Action => async (dispatch, getState, invoke) => {
  let message = playerMessage;
  message.messageId = Guid.create().toString();
  message.senderUserId = getUserId();
  await invoke('playerMessages', playerMessage);
  //dispatch(gameFsa.hideStartScreen());
};