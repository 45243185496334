import React from 'react';
import './SiteHeader.css';

const SiteHeader = () => {
    return (
        <div className="svmpw-header">
            <div className="svmpw-header-content">
                <div className="text-logo">
                    <div className="logo-line1">Silicon Valley</div>
                    <div className="logo-line2">Music Production</div>
                    <div className="logo-line3">Workshop</div>
                </div>
            </div>
        </div>

    )
}

export default SiteHeader;