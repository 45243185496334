import * as React from 'react'
import { connect, ConnectedProps } from 'react-redux';
import { UserMessageAction, UserStatusMessage, UserMessageState } from '../../../models';
import { RootState, SendPlayerMessage, InvokeUserMessage, SetHighQuality } from '../../../redux'
import PlaylistView from '../../elements/Playlist/PlaylistView';
import ConnectedStatus from '../../elements/RealTimeStatus/ConnectedStatus';
import AudioPlayer from '../../elements/AudioPlayer/AudioPlayer';
import { HubConnectionState } from '@microsoft/signalr';
import { getUserId } from '../../../utils/localStorage';
import { Grid } from 'semantic-ui-react';

import './SyncedPlayer.css';

interface ILocalState {
    playerCurrentTime: number,
    playerCurrentTrackIndex: number,
    isSyncActive: boolean,
    currentUserId: string
}


const mapStateToProps = (state: RootState) => ({
    realTimeConnectionStatus: state.realTime.realTimeConnectionStatus,
    selectedTrackIndex: state.musicPlayer.selectedTrackIndex,
    selectedTrack: state.musicPlayer.selectedTrack,
    trackLoadedBy: state.musicPlayer.trackLoadedBy,
    isPlaying: state.musicPlayer.isPlaying,
    useHighQuality: state.musicPlayer.useHighQuality,
});

const mapDispatchToProps = {
    SendPlayerMessage: SendPlayerMessage,
    SendUserMessage: InvokeUserMessage,
    SetHighQuality: SetHighQuality
};

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;
type Props = PropsFromRedux;

class SyncedPlayer extends React.Component<Props, ILocalState> {
    state: ILocalState = {
        playerCurrentTime: 0,
        playerCurrentTrackIndex: -1,
        isSyncActive: true,
        currentUserId: getUserId()
    };

    /*  Page Life Cycle Events  */

    componentDidUpdate(prevProps: Props) {

        if (prevProps.realTimeConnectionStatus !== this.props.realTimeConnectionStatus && this.props.realTimeConnectionStatus === HubConnectionState.Connected) {
            this.sendUserMessage(UserMessageAction.Connected, 0);
        }
        if (prevProps.useHighQuality !== this.props.useHighQuality) {
            this.sendUserMessage(UserMessageAction.StateChanged, 0);
        }

    }

    componentWillUnmount() {
        this.sendUserMessage(UserMessageAction.Disconnected, 0);
        // if (this.state.signalRConnection) {
        //   this.state.signalRConnection.stop();
        // }
    }

    sendUserMessage = (action: UserMessageAction, data: number, sync?: boolean) => {
        //this.setState({playerMessage:playerMessage});
        if (!data) {
            data = 0;
        } else {
            data = data << 5;
        }
        // add the state
        data = data ^ UserMessageState.Connected; //this is really not needed...
        if (this.props.useHighQuality) data = data ^ UserMessageState.QualityHigh;
        console.log('sendUserMessage', 'before sync data', data);
        if ((sync === undefined && this.state.isSyncActive) || sync) data = data ^ UserMessageState.SyncOn;
        console.log('sendUserMessage', 'after sync data', data);

        if (this.props.realTimeConnectionStatus === HubConnectionState.Connected) {
            var userMessage: UserStatusMessage = { sender: '1', action: action, data: data };
            this.props.SendUserMessage(userMessage);
        }
    }

    /*  AudioPlayer Event Handlers  */
    onCurrentTimeChange = (seconds: number) => {
        this.setState({ playerCurrentTime: seconds, playerCurrentTrackIndex: this.props.selectedTrackIndex });
    }

    onSyncClick = (syncState: boolean) => {
        this.setState({ isSyncActive: syncState });
        console.log('sync state changed', syncState);
        this.sendUserMessage(UserMessageAction.StateChanged, 0, syncState);
    }

    onQualityChanged = (highQuality: boolean) => {
        this.props.SetHighQuality(highQuality);
    }

    onTrackLoaded = () => {
        this.sendUserMessage(UserMessageAction.TrackLoaded, this.props.selectedTrackIndex);
    }

    onTrackFinishedPlaying = () => {
        this.sendUserMessage(UserMessageAction.TrackFinishedPlaying, this.props.selectedTrackIndex);
    }

    render() {
        return (
            <Grid className="syncedplayer" stackable reversed='mobile' columns={2} divided style={{ margin: '20px' }}>
                <Grid.Column computer={4} mobile={16}>
                    <div className="syncedplayer-playlist">
                        <PlaylistView />
                    </div>
                    <ConnectedStatus />
                </Grid.Column>
                <Grid.Column computer={12} mobile={16}>
                    <div className="syncedplayer-playercontainer">
                        <AudioPlayer 
                            onSyncClick={this.onSyncClick}
                            onQualityChanged={this.onQualityChanged}
                            onCurrentTimeChange={this.onCurrentTimeChange}
                            onTrackLoaded={this.onTrackLoaded}
                            onTrackFinishedPlaying={this.onTrackFinishedPlaying}
                        />
                    </div>

                </Grid.Column>
            </Grid>

        );
    }
}

export default connector(SyncedPlayer);