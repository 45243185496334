import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import SiteHeader from '../elements/SiteHeader/SiteHeader';
import SyncedPlayer from '../pages/SyncedPlayer/SyncedPlayer';
import eshylogo from '../../logos/Logo6_4.png';

import './App.css';

function App() {
    return (
        <Router>
            <div>
                <SiteHeader />
                <SyncedPlayer />
                <div className="eshylogo">
                    <a href="https://www.soundcloud.com/e-shy/" target="_blank" rel="noopener noreferrer">
                        <img src={eshylogo} className="eshylogo-image" style={{ width: '100px', height: '100px' }} alt="E.Shy Built It" />
                    </a>

                </div>
            </div>
        </Router>
    )
}

export default App;