import { combineReducers } from 'redux';
import RealTimeReducer, { IRealTimeState } from './RealTimeReducer';
import MusicPlayerReducer, { IMusicPlayerState } from './MusicPlayerReducer';

export interface RootState {
    realTime : IRealTimeState,
    musicPlayer: IMusicPlayerState
}

const RootReducer = combineReducers<RootState>({
    realTime: RealTimeReducer,
    musicPlayer: MusicPlayerReducer
});

export default RootReducer;