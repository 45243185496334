import { RealTimeActionTypes, REALTIME_CONNECTION_STATUS_CHANGED } from "../actions/RealTimeActionTypes";
import { HubConnectionState } from "@microsoft/signalr";

export interface IRealTimeState {
    realTimeConnectionStatus: HubConnectionState

}
const initialState: IRealTimeState = {
    realTimeConnectionStatus: HubConnectionState.Disconnected
};


const RealTimeReducer = (state: IRealTimeState = initialState, action: RealTimeActionTypes): IRealTimeState => {
    console.log('RealTimeReducer', 'type', action.type);
    switch (action.type) {
        case REALTIME_CONNECTION_STATUS_CHANGED:
            return { ...state, realTimeConnectionStatus: action.payload };
        default:
            return state;
    }
}

export default RealTimeReducer;
