import { Dispatch } from 'redux';
import RestApi from './RestApi';
import { MusicPlayerActionTypes, FetchPlaylistAction, MUSICPLAYER_FETCHPLAYLIST, FetchPlayListStartedAction, MUSICPLAYER_FETCHPLAYLISTSTARTED, MUSICPLAYER_SETHIGHQUALITY } from './MusicPlayerActionTypes';
import { createAction } from 'typesafe-actions';
import constants from '../../utils/constants';


export const FetchPlaylist = () => async (dispatch: Dispatch<MusicPlayerActionTypes>) => {
    try {
        dispatch<FetchPlayListStartedAction>({
            type: MUSICPLAYER_FETCHPLAYLISTSTARTED
        });
        //[HttpGet("{groupId}/sessions/{sessionId}/playlist")] //http://localhost:5000/api/groups/SVMPW/sessions/svmpw-test-2/playlist/
        //const response = await RestApi.get('/groups/SVMPW/sessions/svmpw-track-feedback-night-2-2020-09-01/playlist');
        const response = await RestApi.get(`groups/SVMPW/sessions/${constants.sessionId}/playlist`);

        dispatch<FetchPlaylistAction>({
            type: MUSICPLAYER_FETCHPLAYLIST,
            payload:response.data
        });
    } catch (e) {
        console.log ('GetPlaylist Exception: ' + e);
    };
};

export const SetHighQuality = createAction(MUSICPLAYER_SETHIGHQUALITY)<boolean>();