import * as signalR from '@microsoft/signalr';
import { SignalMiddleware, MiddlewareConfig } from './Types';
import { HubConnectionState } from '@microsoft/signalr';
import { OnRealTimeConnectionStatusChanged } from '../../actions/RealTimeActions';

const signal = ({ callbacks, onStart = () => { }, url, logLevel = signalR.LogLevel.Error, connectionOptions = {} }: MiddlewareConfig): SignalMiddleware => store => {
    const connection = new signalR.HubConnectionBuilder()
        .configureLogging(logLevel)
        .withUrl(url, connectionOptions)
        .withAutomaticReconnect()
        .build();

    const { callbackMap } = callbacks;
    for (const [name, callback] of callbackMap) {
        connection.on(name, (...args) => {
            callback.call(null, ...args).call(store, store.dispatch.bind(store));
        });
    }
    connection.onclose(() => {
        store.dispatch(OnRealTimeConnectionStatusChanged(HubConnectionState.Disconnected))
    });
    connection.onreconnecting(() => {
        store.dispatch(OnRealTimeConnectionStatusChanged(HubConnectionState.Reconnecting))
    });
    connection.onreconnected(() => {
        store.dispatch(OnRealTimeConnectionStatusChanged(HubConnectionState.Connected))
    })

    connection.start().then(function () {
        store.dispatch(OnRealTimeConnectionStatusChanged(HubConnectionState.Connected))
        onStart();
    }).catch(function (err) {
        return console.error(err.toString());
    });
    return next => action =>
        typeof action === 'function'
            ? action(store.dispatch.bind(store), store.getState.bind(store), connection.invoke.bind(connection))
            : next(action);

}

export default signal;