import { withCallbacks, signalMiddleware, LogLevel } from '../middlewares/redux-signalr';
import { PlayerMessage, UserStatusMessage } from '../../models';
import { OnPlayerMessageReceived, OnUserMessageReceived } from '../actions/RealTimeActions';
import { Dispatch } from '../actions/RealTimeActionTypes';
import { getUserId, getToken, setToken } from '../../utils/localStorage';
import RestApi from '../actions/RestApi';
import jwt_decode from "jwt-decode";

const callbacks = withCallbacks()
    .add('PlayerControlMessage', (playerMessage: PlayerMessage) => (dispatch: Dispatch) => {
        console.log('withSignalR', 'PlayerMessage', playerMessage);
        dispatch(OnPlayerMessageReceived(playerMessage));
    })
    .add('UserMessage', (userMessage: UserStatusMessage) => (dispatch: Dispatch) => {
        console.log('withSignalR', 'UserMessage', userMessage);
        dispatch(OnUserMessageReceived(userMessage));
    })

export const signal = signalMiddleware({
    callbacks,
    //url: `${constants.apiBaseUrl}/api/${getUserId()}`,
    url: process.env.REACT_APP_API_REALTIME_URL!,
    logLevel: LogLevel.Debug,
    connectionOptions: {
        skipNegotiation: false,
        accessTokenFactory: () => GetGuestToken()
    }
})

export const GetGuestToken = async (): Promise<string> => {
    try {
        console.log('GetGuestToken')
        let token = getToken();
        console.log('GetGuestToken', token)
        if (token && !isTokenExpired(token)) {
            return token;
        }

        const response = await RestApi.get(`user/guest?userId=${getUserId()}`);
        setToken(response.data);
        return token;

    } catch (error) {
        console.log('error getting token');
    }
    return '';
}

const isTokenExpired = (token: string) => {
    const decodedToken: any = jwt_decode(token);
    return (decodedToken && Date.now() >= decodedToken.exp * 1000 - 5000);
}

