import { createStore, applyMiddleware } from 'redux'
import RootReducer from './reducers/RootReducer';
//import realTimeReducer from './reducers/RealTimeReducer';
import {composeWithDevTools} from 'redux-devtools-extension';
//import thunk from 'redux-thunk'
import { signal } from './helpers/withSignalR';
 
const Store = createStore(RootReducer, composeWithDevTools(applyMiddleware(signal)));


export default Store;