import React from 'react'

interface IProps {
    formatText: string,
    loadedTrackUri?: string,

}
//selectedFormat: 
const AudioFormatDisplay: React.FC<IProps> = ({ formatText, loadedTrackUri }) => {
    const displayOn = () => {
        return loadedTrackUri?.substring(loadedTrackUri.lastIndexOf('.') + 1).toLowerCase() === formatText.toLowerCase();
    }
    return (
        <div style={{ margin: '0 10px 0 0', opacity: displayOn() ? 1 : 0.25 }}>
            {formatText}
        </div>
    )
}

export default AudioFormatDisplay
