import { Track } from "./MusicPlayerTypes";

export interface PlayerMessage {
    messageId: string,
    recipientUserId?: string,
    senderUserId?: string,
    currentTrack?: Track,
    selectedTrackIndex:number,
    isPlaying: boolean,
    currentTime: number,
    reSync?: boolean
}

export enum UserMessageAction {
    StateChanged = 1,
    Connected = 2,
    Disconnected = 3,
    TrackLoaded = 4,
    TrackFinishedPlaying = 5,
}

export interface UserStatusMessage {
    messageId?: string,
    sessionId?:string,
    sender:string,
    recipient?:string,
    action: UserMessageAction,
    data?: number
}

export enum UserMessageState {
    Connected = 1,
    Disconnected = 2,
    SyncOn = 4,
    QualityHigh = 8
}

export interface ConnectedUser {
    userId: string,
    lastLoadedTrackIndex:number,
    lastFinishedTrackIndex:number,
    syncOn:boolean,
    highQuality:boolean,
    lastUpdated?:Date
}

export interface GroupUser {
    userId: string,
    groupName: string,
    action: string
}

// export type ClientPrincipal = {
//     "identityProvider": string,
//     "userId": string,
//     "userDetails": string,
//     "userRoles": string[]
// }