/* Actions for Real Time Messaging (used by SignalR or Firebase to dispatch received messages from server as well as send messages out to the server) */

import { UserStatusMessage, PlayerMessage, GroupUser } from "../../models";
import { HubConnectionState } from "@microsoft/signalr";
import { SignalAction, AnyAction, SignalDispatch } from "../middlewares/redux-signalr";
import { RootState } from "../reducers/RootReducer";

 
export type Action<ReturnValue = void> = SignalAction<
  ReturnValue,
  RootState,
  AnyAction
>;
export type Dispatch<Action extends AnyAction = AnyAction> = SignalDispatch<
  RootState,
  Action
>;

//export const REALTIME_USER_DISCONNECTED = "REALTIME_USER_DISCONNECTED";
export const REALTIME_CONNECTION_STATUS_CHANGED = "REALTIME_CONNECTION_STATUS_CHANGED";  
export const REALTIME_USERMESSAGE_RECEIVED = "REALTIME_USERMESSAGE_RECEIVED";
export const REALTIME_PLAYERMESSAGE_RECEIVED = "REALTIME_PLAYERMESSAGE_RECEIVED";
export const REALTIME_SEND_USERMESSAGE = "REALTIME_SEND_USERMESSAGE";
export const REALTIME_SEND_PLAYERMESSAGE = "REALTIME_SEND_PLAYERMESSAGE";
export const REALTIME_JOIN_GROUP = "REALTIME_JOIN_GROUP";

export interface RealTimeConnectionStatusChangedAction {
    type: typeof REALTIME_CONNECTION_STATUS_CHANGED;
    payload: HubConnectionState;
}

export interface RealTimeUserMessageReceivedAction {
    type: typeof REALTIME_USERMESSAGE_RECEIVED;
    payload: UserStatusMessage;
}

export interface RealTimePlayerMessageReceivedAction {
    type: typeof REALTIME_PLAYERMESSAGE_RECEIVED;
    payload: PlayerMessage;
}

export interface RealTimeSendUserMessageAction {
    type: typeof REALTIME_SEND_USERMESSAGE;
    payload: UserStatusMessage;
}

export interface RealTimeSendPlayerMessageAction {
    type: typeof REALTIME_SEND_PLAYERMESSAGE;
    payload: PlayerMessage;
}

export interface RealTimeJoinGroupAction {
    type: typeof REALTIME_JOIN_GROUP;
    payload: GroupUser
}

export type RealTimeActionTypes = RealTimeConnectionStatusChangedAction | RealTimeUserMessageReceivedAction | RealTimePlayerMessageReceivedAction | RealTimeSendUserMessageAction | RealTimeSendPlayerMessageAction | RealTimeJoinGroupAction;
