import * as React from 'react';
import WaveSurfer from 'wavesurfer.js';

import { PlayerMessage, Track } from '../../../models';
import { connect, ConnectedProps } from 'react-redux';
import { RootState } from '../../../redux';
import { getUserId } from '../../../utils/localStorage';

import TimeDisplay from './TimeDisplay';
import ProgressBar from '../ProgressBar/ProgressBar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStepBackward, faPlay, faPause, faVolumeUp, faSyncAlt, faCompactDisc } from '@fortawesome/free-solid-svg-icons';
import { Popup } from 'semantic-ui-react';

import './AudioPlayer.css';
import "../../../styles/metal.css";
import AudioFormatDisplay from './AudioFormatDisplay';

export interface AudioPlayerState {
  currentTrack?: Track,
  loadedTrackUriWithQuality?: string,
  volume: number,
  syncOn: boolean,
  muted: boolean,
  isPlaying: boolean,
  currentTime: number,
  trackLength: number,
  trackLoadingProgress: number,
  showProgressBar: boolean,
  status: string
}

export interface AudioPlayerProps {
  message?: PlayerMessage,
  onTrackLoaded?: () => void,
  onTrackFinishedPlaying?: () => void,
  onInteraction?: (seconds?: number) => void,
  onCurrentTimeChange?: (seconds: number) => void,
  onPlayPauseClick?: () => void,
  onRewindClick?: () => void,
  onSyncClick?: (state: boolean) => void,
  onQualityChanged?: (state: boolean) => void
}

const mapStateToProps = (state: RootState) => ({
  useHighQuality: state.musicPlayer.useHighQuality,
  selectedTrack: state.musicPlayer.selectedTrack,
  isPlaying: state.musicPlayer.isPlaying,
  currentTime: state.musicPlayer.currentTime,
  isCurrentUserInControl: state.musicPlayer.trackLoadedBy === getUserId()
})

const mapDispatchToProps = {
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux & AudioPlayerProps


class AudioPlayerInternal extends React.Component<Props, AudioPlayerState> {
  state: AudioPlayerState = {
    loadedTrackUriWithQuality: '',
    volume: 1,
    syncOn: true,
    muted: false,
    isPlaying: false,
    currentTime: 0,
    trackLength: 0,
    trackLoadingProgress: 0,
    showProgressBar: false,
    status: ''
  }

  waveform: WaveSurfer | undefined;

  componentDidMount() {
    this.createWaveSurfer();
  }

  async componentDidUpdate(prevProps: Props) {
    //Check if the track, time or play state changed and call handlePlayerAction with new values
    if (this.props.selectedTrack !== prevProps.selectedTrack || this.props.isPlaying !== prevProps.isPlaying || this.props.currentTime !== prevProps.currentTime) {
      this.handlePlayerPropsChange(this.props.currentTime);
    } else if (this.props.useHighQuality !== prevProps.useHighQuality) {
      this.handlePlayerPropsChange(this.state.currentTime);
    }
  }

  async handlePlayerPropsChange(startTime: number) {
    let loadTime = await this.handleTrackChange(this.props.selectedTrack);
    let fromTime = this.props.isPlaying ? startTime + loadTime : startTime;
    this.handlePlayerAction(this.props.isPlaying, fromTime);
  }

  //this.props.selectedTrack
  getTrackUriWithQuality(track: Track): string {
    if (track?.fileName) {
      let fileName = track.fileName;
      if (this.props.useHighQuality && track.highQualityExt !== '') {
        fileName = fileName.replace('.mp3', '.' + track.highQualityExt);
      }
      return `${process.env.REACT_APP_AZUREMUSIC_URI}${fileName}`;
    }
    return '';
  }
  trackLoaded: boolean = false;
  async handleTrackChange(trackToLoad: Track | undefined): Promise<number> {
    if (trackToLoad === undefined) return 0;
    let startTime = Date.now();

    let trackUri = this.getTrackUriWithQuality(trackToLoad);
    if (this.state.currentTrack === undefined || trackUri !== this.state.loadedTrackUriWithQuality) {
      if (this.waveform?.isPlaying()) {
        this.waveform.stop();
      }
      this.setState({ currentTrack: trackToLoad, trackLength: 0 });
      this.trackLoaded = false;
      //this.setState({currentTime:-1,trackLength:-1}); //Hides the time displays while loading a track
      this.waveform?.load(trackUri);
      //wait for ready on wave form
      while (!this.trackLoaded) {
        await new Promise(resolve => setTimeout(resolve, 250));
      }
      let loadTimeOffset = (Date.now() - startTime) / 1000;

      this.setState({ currentTrack: trackToLoad, loadedTrackUriWithQuality: trackUri });

      return loadTimeOffset;
    }

    return 0;
  }


  async handlePlayerAction(play: boolean, fromTime: number) {
    if (!this.state.syncOn) { return; }
    try {
      if (fromTime > 0) {
        //seek or play from 
        this.waveform?.setCurrentTime(fromTime);
        //Give waveform a few milliseconds or it sometime won't start playing after setting the time 
        await new Promise(resolve => setTimeout(resolve, 100));
      }
      console.log('handlePlayerAction', 'play', play, 'fromTime', fromTime);
      if (play && !this.waveform?.isPlaying()) {
        //need to play, currently not playing
        this.waveform?.play();
      }
      if (!play) {
        //stop pause logic. if was playing based on from time, if wasn't playing just rewind if fromTime is 0
        if (this.waveform?.isPlaying()) {
          if (fromTime === 0) {
            this.waveform?.stop();
          } else {
            this.waveform?.pause();
          }
        } else if (fromTime === 0) {
          this.waveform?.setCurrentTime(0);
        }
      }

    } catch (error) {
      console.error('AudioPlayer - handlePlayerAction - error:', error);
    }
  }

  createWaveSurfer() {
    this.waveform = WaveSurfer.create({
      barWidth: 4,
      barRadius: 2,
      cursorWidth: 2,
      container: '#waveform',
      scrollParent: false,
      hideScrollbar: true,
      height: 128,
      progressColor: '#F90000AA',
      responsive: true,
      backgroundColor: 'transparent',
      waveColor: '#17E8D2',
      cursorColor: '#fff',
      pixelRatio: 1,
      interact: !this.state.syncOn
    });

    if (this.waveform) {
      var canvas = document.createElement('canvas') as HTMLCanvasElement;
      canvas.height = 128;
      var ctx = canvas.getContext('2d') as CanvasRenderingContext2D;

      var linGrad = ctx.createLinearGradient(0, 0, 0, 128);
      linGrad?.addColorStop(0.5, 'rgba(23, 232, 210, 1.000)');
      linGrad?.addColorStop(0.5, 'rgba(10, 107, 95, 1.000)');
      if (linGrad) this.waveform.setWaveColor(linGrad);

      //Declare event hadnlers
      this.waveform.on("audioprocess", this.onWaveformAudioProcess.bind(this));
      this.waveform.on("ready", this.onWaveformTrackLoaded.bind(this));
      this.waveform.on("finish", this.onWaveformTrackPlaybackFinished.bind(this));
      this.waveform.on("loading", this.onWaveformTrackLoadingProgress.bind(this)); //use to draw progress bar animation on top of waveform placeholder
      this.waveform.on("seek", this.onWaveformInteraction.bind(this));
      this.waveform.on("volume", this.onWaveformVolumeChanged.bind(this));

    }

  }

  /* Player Controls Event Handlers */

  onPlayPauseClick = () => {
    if (!this.state.syncOn) {
      if (this.state.isPlaying) {
        this.waveform?.pause();
      } else {
        this.waveform?.play();
      }
      this.setState({ isPlaying: !this.state.isPlaying });
    }
  };

  onRewindClick = () => {
    if (!this.state.syncOn) {
      if (this.waveform?.isPlaying()) {
        this.waveform?.stop();
      } else {
        this.waveform?.setCurrentTime(0);
      }
      this.setState({ isPlaying: false });
    }
  };

  onSyncClick = () => {
    let newSyncState = !this.state.syncOn;

    this.setState({ syncOn: newSyncState });
    this.waveform?.toggleInteraction();

    if (this.props.onSyncClick) {
      this.props.onSyncClick(newSyncState);
    }
  }

  onQualityClick = () => {
    if (this.props.onQualityChanged) {
      this.props.onQualityChanged(!this.props.useHighQuality);
    }
  }

  onMuteClick = () => {
    if (this.state.muted) {
      this.setState({ muted: false, volume: this.state.volume === 0 ? 0.5 : this.state.volume });
      this.waveform?.setMute(false);
      this.waveform?.setVolume(this.state.volume === 0 ? 0.5 : this.state.volume);
    } else {
      this.setState({ muted: true });
      this.waveform?.setMute(true);
    }
  }

  onVolumeChange = (e: any) => {
    let newVolumeLevel = Math.round(e.target.value * 100) / 100;
    this.setState({ volume: newVolumeLevel, muted: newVolumeLevel === 0 });
    this.waveform?.setVolume(newVolumeLevel);
    this.waveform?.setMute(newVolumeLevel === 0.00);
  }


  /* WaveForm Event Handlers */
  onWaveformAudioProcess() {
    var currentTimeInSeconds = this.waveform?.getCurrentTime() ?? 0;
    this.setState({ currentTime: currentTimeInSeconds });
    if (this.props.onCurrentTimeChange) {
      this.props.onCurrentTimeChange(currentTimeInSeconds)
    };
  }

  onWaveformTrackLoaded() {
    var durationInSeconds = this.waveform?.getDuration() ?? 0;
    this.trackLoaded = true;
    this.setState({ trackLength: durationInSeconds, currentTime: 0, showProgressBar: false });
    if (this.props.onTrackLoaded) this.props.onTrackLoaded();
  }

  onWaveformTrackPlaybackFinished() {
    if (this.props.onTrackFinishedPlaying) this.props.onTrackFinishedPlaying();

  }

  onWaveformTrackLoadingProgress(progress: number) {
    this.setState({ trackLoadingProgress: progress, showProgressBar: true });
  }

  onWaveformVolumeChanged(volume: number) {
    //this.setState({muted: Math.round(volume*100)/100===0})
    //this.waveform?.setMute(volume==0);
  }

  onWaveformInteraction() {
    if (this.props.onInteraction && this.state.syncOn) {
      let seekTime = this.waveform?.getCurrentTime();
      //only seek if it's 2 seconds away from current time
      if (seekTime !== undefined && seekTime > 0 && Math.abs(seekTime - this.state.currentTime) > 2) {
        this.props.onInteraction(seekTime);
      }
    }
    this.onWaveformAudioProcess();
  }

  //TODO: Create a FormattedTime component for this (which will display the time using TimeDisplay)
  formatTime(seconds: number) {
    if (seconds === 0) return '0:00';
    var minutes = Math.floor(seconds / 60);
    var remainingSeconds = Math.floor(seconds - (minutes * 60));
    return minutes + ':' + (remainingSeconds < 10 ? '0' : '') + remainingSeconds;
  }

  render() {
    const trackDisplay = (track?: Track) => {
      if (track === undefined) return 'no track loaded';
      return track?.artist + ((track?.artist !== undefined && track?.artist !== '' && track?.title !== undefined && track?.title !== '') ? ' - ' : '') + track?.title;
    }

    return (
      <div className="svmpw-audioplayer-container metalcontainer">
        <div className="svmpw-audioplayer-header ">
          <div className="svmpw-audioplayer-tracktitle">
            <div className="svmpw-audioplayer-tracknumber"><TimeDisplay id="tracknumber" timeValue={this.state.currentTrack?.trackNumber.toString()} /></div>
            <h1>{this.state.showProgressBar ? "Loading Track" : trackDisplay(this.state.currentTrack)}</h1>
          </div>
          <div className="svmpw-audioplayer-timedisplays" style={{ display: this.state.trackLength >= 0 ? 'flex' : 'none', opacity: this.state.trackLength === 0 ? 0.15 : 1 }}>
            <div className="svmpw-audioplayer-formatdisplays">
              <AudioFormatDisplay formatText="MP3" loadedTrackUri={this.state.loadedTrackUriWithQuality} />
              <AudioFormatDisplay formatText={this.state.currentTrack?.highQualityExt?.toUpperCase() || ''} loadedTrackUri={this.state.loadedTrackUriWithQuality} />
            </div>
            <TimeDisplay id="currentTime" timeValue={this.formatTime(this.state.currentTime)} />
            <span className="svmpw-audioplayer-timedisplays-separator">/</span>
            <TimeDisplay id="trackLength" timeValue={this.formatTime(this.state.trackLength)} />
          </div>

        </div>


        <div className="svmpw-audioplayer-display glass_effect">
          <div className="svmpw-waveform">
            <ProgressBar progress={this.state.trackLoadingProgress} visible={this.state.showProgressBar} />
            <div id="waveform">

            </div>
            <canvas id="ws-canvas" width="1px" height="128px"></canvas>
          </div>
        </div>


        <div className="svmpw-audioplayer-controls ">
          <div className="svmpw-audioplayer-controls-sync">
            <Popup trigger={
              <button className={"metal linear oval" + (this.props.useHighQuality ? " pressed" : "")} onClick={this.onQualityClick} >
                <FontAwesomeIcon icon={faCompactDisc}/>
              </button>
            } position='top center'
              wide
              mouseEnterDelay={1000}
              mouseLeaveDelay={1000}
              on='hover'>
              <Popup.Header>Select Quality</Popup.Header>
              <Popup.Content>By default the files loaded are MP3s encoded with 320kbps CBR. If you want lossless quality you can switch by clicking on the CD toggle (if a track was sent in as an mp3, it will still be an mp3…)</Popup.Content>
            </Popup>
            <Popup trigger={
              <button className={"metal radial" + (this.state.syncOn ? " pressed" : "")} onClick={this.onSyncClick}>
                <FontAwesomeIcon icon={faSyncAlt} />
              </button>

            } position='bottom center'
              wide
              mouseEnterDelay={1000}
              mouseLeaveDelay={1000}
              on='hover'>
              <Popup.Header>Sync On/Off</Popup.Header>
              <Popup.Content>When on, playback will be controlled remotely to keep everyone in sync. When off you can control playback manually but tracks will still be loaded remotely.</Popup.Content>
            </Popup>
          </div>
          <div className="svmpw-audioplayer-controls-player">
            <button className="metal linear" onClick={this.onRewindClick}>
              <FontAwesomeIcon icon={faStepBackward} size="lg" />
            </button>

            <button className="metal linear" onClick={this.onPlayPauseClick}>
              {!((this.state.syncOn && this.props.isPlaying) || (!this.state.syncOn && this.state.isPlaying)) ? <FontAwesomeIcon icon={faPlay} size="lg" /> : <FontAwesomeIcon icon={faPause} size="lg" />}
            </button>
          </div>

          <div className="svmpw-audioplayer-controls-loudness">
            <div className="svmpw-audioplayer-controls-volume metal linearslider">
              <input id="volume" type="range" min="0" max="1" step="0.01" onChange={this.onVolumeChange} value={this.state.volume} />
            </div>
            <button className={"metal radial" + (!this.state.muted ? " pressed" : "")} onClick={this.onMuteClick}>
              <FontAwesomeIcon icon={faVolumeUp} />
            </button>
          </div>
        </div>
      </div>

    );
  }
};

let AudioPlayer = connector(AudioPlayerInternal);
export default AudioPlayer;
