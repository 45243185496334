/* Actions for the Music Player - This will control the player itself and send status updates from the player */

import { Track } from "../../models";

//export const REALTIME_USER_DISCONNECTED = "REALTIME_USER_DISCONNECTED";
export const MUSICPLAYER_FETCHPLAYLIST = "MUSICPLAYER_FETCHPLAYLIST";
export const MUSICPLAYER_FETCHPLAYLISTSTARTED = "MUSICPLAYER_FETCHPLAYLISTSTARTED";
export const MUSICPLAYER_SETHIGHQUALITY = "MUSICPLAYER_SETHIGHQUALITY";

export interface FetchPlaylistAction {
    type: typeof MUSICPLAYER_FETCHPLAYLIST;
    payload: Array<Track>;
}

export interface FetchPlayListStartedAction {
    type: typeof MUSICPLAYER_FETCHPLAYLISTSTARTED;
    //payload: string;
}

export interface SetHighQualityAction {
    type: typeof MUSICPLAYER_SETHIGHQUALITY,
    payload: boolean
}

export type MusicPlayerActionTypes = FetchPlaylistAction | FetchPlayListStartedAction | SetHighQualityAction;
