/*
Time Display Component
By: Ishai Hachlili
Based On: https://www.goocode.net/js/9-use-jquery-and-css3-to-create-digital-clock-css3-part.html

import TimeDisplay from  '../../elements/AudioPlayer/TimeDisplay';
<TimeDisplay timeValue="3:45" />

*/
import * as React from 'react';
import './TimeDisplay.css';

//export default Digit;
export interface TimeDisplayProps {
    id: string;
    timeValue: string;
}

class TimeDisplay extends React.Component<TimeDisplayProps, {}> {
    public static defaultProps = {
        timeValue: '',
    };
    //we get a string for a time display (5:43) we split it to an array of digits and render the digit for each one

    //On updates only change the styles (opacity) of each digit and segment. Unless the length of characters changed
    shouldComponentUpdate(nextProps: TimeDisplayProps, nextState: any) {
        if (this.props.timeValue !== nextProps.timeValue) {
            if (this.props.timeValue.length !== nextProps.timeValue.length) {
                //the length of the string changed, we need to re-render to generate the digit elements
                return true;
            }
            //check if there's a dots element in a different posistion in the two strings (there can be more than one, get their positions and compare). If different, return true
            if (this.getDotsPositions(this.props.timeValue) !== this.getDotsPositions(nextProps.timeValue)) {
                return true;
            }

            //go digit by digit and update opacity of sesgments
            let currentCharacters = this.props.timeValue.split("");
            let newCharacters = nextProps.timeValue.split("");
            currentCharacters.forEach((currentDigit, index) => {
                if (newCharacters[index] !== currentDigit && currentDigit !== ':') {
                    var digitValue: number = +newCharacters[index];
                    for (var segment = 0; segment < 7; segment++) {
                        var newOpacity = this.getOpacity(digitValue, segment);
                        var segmentElement = document.getElementById(this.props.id + "_d" + index + "s" + segment);
                        if (segmentElement) {
                            segmentElement.setAttribute("style", "opacity: " + newOpacity);
                        }
                    }
                }
            })
        }
        return false;
    }

    getDotsPositions = (timeValueToCheck: string) => {
        var stringToFind = ":";
        var searchStrLen = timeValueToCheck.length;
        if (searchStrLen === 0) {
            return "";
        }
        var startIndex = 0, index, indices = [];
        while ((index = timeValueToCheck.indexOf(stringToFind, startIndex)) > -1) {
            indices.push(index);
            startIndex = index + searchStrLen;
        }
        //return indices;
        return indices.join(',');

    }

    getOpacity = (digitValue: number, segment: number) => {
        const offOpacity: number = 0.15;

        if (digitValue === undefined) return 0;

        var digitBits = [125, 80, 55, 87, 90, 79, 111, 81, 127, 91];
        if (digitBits[digitValue] & (1 << segment)) {
            return 1;
        }

        return offOpacity;
    }

    render() {
        return (
            <div id="time" className="light">
                <div className="display">
                    <div className="digits">
                        {this.props.timeValue.split("").map((digitString, index) => {
                            if (digitString === ":") {
                                return <div key={index} className="dots" />;
                            } else {
                                var digitValue: number = +digitString;

                                return (
                                    <div key={index}>
                                        {Array.from(Array(7), (e, i) => {
                                            let itemId = this.props.id + "_d" + index + "s" + i;
                                            return <span key={itemId} id={itemId} className={"d" + (i + 1)} style={{ opacity: this.getOpacity(digitValue, i) }} />
                                        })}
                                    </div>
                                );
                            }
                        })}

                    </div>
                </div>
            </div>
        );
    }
};
export default TimeDisplay;