import { Guid } from "guid-typescript";

export const getUserId = (): string => {

    const paramName = "userId";
    if (!(paramName in window.localStorage)) {
        let uid = Guid.create().toString();
        window.localStorage.setItem(paramName, uid!);
    }

    let userId = window.localStorage.getItem(paramName);
    return userId!;
}



export const setToken = (token: string) => {
    window.localStorage.setItem("svjwt", JSON.stringify(token))
}

export const getToken = (): string => {
    const paramName = "svjwt";
    if ((paramName in window.localStorage)) {
        return JSON.parse(window.localStorage.getItem(paramName)!);
    }
    return '';
}

export const setUseHighQuality = (highQuality: boolean) => {
    window.localStorage.setItem("highQuality", JSON.stringify(highQuality))
}

export const getUseHighQuality = (): boolean => {
    return JSON.parse(window.localStorage.getItem("highQuality") ?? 'false');
} 