import axios from 'axios';

export function setToken(token: string) {
    axios.defaults.headers.common['Authorization'] =
        `Bearer ${token}`;
}

export default axios.create({
    baseURL: process.env.REACT_APP_API_URL,

    headers: {
        'Content-Type': 'application/json',
    }
});