import * as React from 'react';

import './ProgressBar.css';

export interface ProgressBarProps {
    progress:number,
    visible: boolean

}
class ProgressBar extends React.Component<ProgressBarProps, {}> {

    render() {
        return (
            <div className="container" style={{width:`100%`, display: `${this.props.visible ? 'block' : 'none'}`}}>
                <div className="progressbar-container">
                    <div className="progressbar-complete" style={{width: `${this.props.progress}%`}}>
                    </div>
                    <span className="progress">{this.props.progress}%</span>
                </div>
            </div>
        )
    }
};


export default ProgressBar;
