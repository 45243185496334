import { Track } from "../../models";
import { REALTIME_PLAYERMESSAGE_RECEIVED } from "../actions/RealTimeActionTypes";
import { MUSICPLAYER_FETCHPLAYLIST, MUSICPLAYER_FETCHPLAYLISTSTARTED, MUSICPLAYER_SETHIGHQUALITY } from "../actions/MusicPlayerActionTypes";
import { getUserId, getUseHighQuality, setUseHighQuality } from "../../utils/localStorage";

export interface IMusicPlayerState {
    playlist: Track[],
    isPlaylistLoading: boolean,
    useHighQuality: boolean,
    selectedTrackIndex: number,
    selectedTrack?: Track,
    trackLoadedBy?: string,
    isPlaying: boolean,
    currentTime: number
}
const initialState: IMusicPlayerState = {
    playlist: [],
    isPlaylistLoading: false,
    useHighQuality: getUseHighQuality(),
    selectedTrackIndex: -1,
    isPlaying: false,
    currentTime: 0
};



const MusicPlayerReducer = (state: IMusicPlayerState = initialState, action: any): IMusicPlayerState => {
    switch (action.type) {
        case REALTIME_PLAYERMESSAGE_RECEIVED: /* Just add the message to the queue, will be handled by the player props */
            let currentUserId = getUserId();
            if (action.payload.recipientUserId === undefined || action.payload.recipientUserId === 'all' || action.payload.recipientUserId === currentUserId) {

                if (state.selectedTrack?.trackNumber !== action.payload.currentTrack?.trackNumber || state.trackLoadedBy === action.payload.senderUserId) {
                    // if we received a new track, the sender of the message is our host and the only one we should listen to for control messages on this track
                    // if it's same track means it's a control message and it must come from the same host
                    // so either the track is different or the sender is the same to change the state, otherwise we do nothing
                    return {
                        ...state,
                        selectedTrack: action.payload.currentTrack !== null ? action.payload.currentTrack : state.selectedTrack,
                        selectedTrackIndex: action.payload.currentTrack !== null ? action.payload.selectedTrackIndex : state.selectedTrackIndex,
                        trackLoadedBy: action.payload.currentTrack !== null ? action.payload.senderUserId : state.trackLoadedBy,
                        isPlaying: action.payload.isPlaying,
                        currentTime: action.payload.currentTime,
                    }
                }
                return state;
            }
            return state;
        case MUSICPLAYER_FETCHPLAYLIST:
            return { ...state, isPlaylistLoading: false, playlist: action.payload };
        case MUSICPLAYER_FETCHPLAYLISTSTARTED:
            return { ...state, isPlaylistLoading: true };
        case MUSICPLAYER_SETHIGHQUALITY:
            setUseHighQuality(action.payload);
            return { ...state, useHighQuality: action.payload };
        default:
            return state;
    }
}

export default MusicPlayerReducer;
