import * as React from 'react';
import PlaylistItem from './PlaylistItem';
import { Track } from '../../../models';

import { connect, ConnectedProps } from 'react-redux';
import { RootState, FetchPlaylist, SendPlayerMessage, InvokePlayerMessage } from '../../../redux';

import { Dimmer, Loader, List } from 'semantic-ui-react';

import './PlaylistView.css';

export interface PlaylistState {
    selectedTrackIndex: number,
}

const mapStateToProps = (state: RootState) => ({
    playlist: state.musicPlayer.playlist,
    isPlaylistLoading: state.musicPlayer.isPlaylistLoading,
    useHighQuality: state.musicPlayer.useHighQuality,
    selectedTrackIndex: state.musicPlayer.selectedTrackIndex,
    realTimeConnectionStatus: state.realTime.realTimeConnectionStatus,
})

const mapDispatchToProps = {
    FetchPlaylist: FetchPlaylist,
    SendPlayerMessage: SendPlayerMessage,
    InvokePlayerMessage: InvokePlayerMessage
}

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = PropsFromRedux

class PlaylistViewInternal extends React.Component<Props, PlaylistState> {
    state: PlaylistState = {
        selectedTrackIndex: this.props.selectedTrackIndex,
    };

    componentDidMount() {
        this.props.FetchPlaylist();
    }

    getTotalsTrack = (tracks: Track[]) => {

        var track = { title: 'Playlist', artist: '', trackNumber: -1, fileName: '', highQualityExt: '', highQualitySize: 0, regQualitySize: 0 };
        if (tracks === undefined || tracks.length === 0) return track;

        track.regQualitySize = tracks.reduce((acc, track) => acc + track.regQualitySize, 0);
        track.highQualitySize = tracks.reduce((acc, track) => acc + track.highQualitySize, 0);

        return track;
    }

    render() {

        return (
            <div className="svmpw-playlist">
                <List divided verticalAlign='middle' className="svmpw-playlist-tracks">
                    <List.Header as='h3'>Session Playlist</List.Header>
                    {this.props.playlist?.map((track, index) => (
                        <PlaylistItem key={index} index={index} track={track} useHighQuality={this.props.useHighQuality} selected={index === this.props.selectedTrackIndex} />
                    ))}
                </List>
                <List divided verticalAlign='middle' style={{ overflowY: 'auto' }}>
                    <PlaylistItem key={-1} index={-1} track={this.getTotalsTrack(this.props.playlist)} useHighQuality={this.props.useHighQuality} />
                </List>

                {this.props.isPlaylistLoading ? <Dimmer active style={{ borderRadius: '10px' }}><Loader size='medium'>Loading</Loader></Dimmer> : null}
            </div>
        );
    }

};


let PlaylistView = connector(PlaylistViewInternal);
export default PlaylistView;
