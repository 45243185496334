import React from 'react'
import { RootState } from '../../../redux';
import { useSelector } from 'react-redux';
import { HubConnectionState } from '@microsoft/signalr';

import './ConnectedStatus.css';

const ConnectedStatus: React.FC = () => {

    const realTimeConnectionStatus = useSelector((state: RootState) => state.realTime.realTimeConnectionStatus);

    return (
        <div className="svmpw-connectedstatus">
            <div className="svmpw-connectionstatus-content">
                <div className={"circle spin " + (realTimeConnectionStatus === HubConnectionState.Connected ? "realtime-connected" : "realtime-disconnected")} />
                <span className="svmpw-connectedstatus-text">{realTimeConnectionStatus}</span>
            </div>
        </div>
    );
};

export default ConnectedStatus;
