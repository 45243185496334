import * as React from 'react';
import { Track } from '../../../models';

import './PlaylistItem.css';
import { List } from 'semantic-ui-react';

export interface PlaylistItemProps {
    index: number,
    track?: Track,
    useHighQuality: boolean,
    selected?: boolean,
    onTrackSelected?: (trackIndex: number) => void
}
class PlaylistItem extends React.Component<PlaylistItemProps, {}> {
    handleClick = () => {
        if (this.props.onTrackSelected) {
            this.props.onTrackSelected(this.props.index);
        }
    };

    render() {
        const formatTrackTitle = (artist?: string, title?: string, trackNumber?: number) => {
            let trackNumberFormatted = (trackNumber && trackNumber >= 0) ? `${trackNumber}. ` : '';
            let artistTitleSeparator = (artist && title && artist !== '' && title !== '') ? ' - ' : '';
            return `${trackNumberFormatted}${artist}${artistTitleSeparator}${title}`;
        }
        const formatTime = (seconds: number): string => {
            if (seconds === 0) return '0:00';
            var minutes = Math.floor(seconds / 60);
            var remainingSeconds = Math.floor(seconds - (minutes * 60));
            return minutes + ':' + (remainingSeconds < 10 ? '0' : '') + remainingSeconds;
        }

        return (
            <List.Item key={this.props.track?.trackNumber} onClick={this.handleClick.bind(this)}>
                <div className={"playlist-item " + (this.props.selected ? 'playlist-item-selected active' : '')}>
                    <span>{formatTrackTitle(this.props.track?.artist, this.props.track?.title, this.props.track?.trackNumber)}</span>
                    <span className='right'>{this.props.track && (this.props.track?.trackLength ?? 0) > 0 ? formatTime(this.props.track?.trackLength!) : ''}</span>
                    <span className='right'>{this.props.useHighQuality ? this.props.track?.highQualitySize : this.props.track?.regQualitySize} MB</span>
                </div>
            </List.Item>
        );
    }

};


export default PlaylistItem;